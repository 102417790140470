/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { memo } from 'react';
import nextId, { useId } from 'react-id-generator';
import slugify from 'slugify';
import { AmazonButton } from '../AmazonBar';
import {
  BookCardAuthor,
  BookCardContainer,
  BookCardMetaContainer,
  BookCardMetaItem,
  BookCardRating,
  BookCardReadingEase,
  BookCardReadTime,
  BookCardTitle,
  BookCover,
} from '../ui/book/bookUi';
import ExpandCollapse from '../ui/expander/expander';

const BookCard = memo(
  ({
    slug,
    fixed,
    rating,
    readEase,
    readTime,
    bookLink,
    title,
    bookAuthor,
    publicationYear,
    read,
    pages,
    categories,
    body,
    isMobile,
    paragraphCount,
  }) => {
    const coverImageWidth = isMobile ? '125px' : '160px';
    const coverImageHeight = isMobile ? '188px' : '240px';
    const key = nextId();
    const categoryKeys = useId(categories.length);
    return (
      <BookCardContainer isMobile={isMobile} key={key}>
        <div
          className="w-full md:w-1/4 flex flex-row md:flex-col"
          css={css`
            color: var(--textLabel);
          `}
        >
          {fixed && (
            <BookCover
              slug={slug}
              fixed={fixed}
              height={coverImageHeight}
              width={coverImageWidth}
            />
          )}
          <div className="text-sm w-auto mt-6 md:mt-1 md:mb-4 font-mono">
            <BookCardRating rating={rating} />
            <BookCardReadingEase readability={readEase} isMobile={isMobile} />
            <br />
            <BookCardReadTime readTime={readTime} isMobile={isMobile} />
            <div className="mt-3">
              <AmazonButton link={bookLink} />
            </div>
          </div>
        </div>
        <div className="w-full md:w-3/4 px-2">
          <BookCardTitle title={title} slug={slug} />
          <BookCardAuthor bookAuthor={bookAuthor} />
          <BookCardMetaContainer>
            <BookCardMetaItem label="Published:" icon="fa-book-open">
              {publicationYear}
            </BookCardMetaItem>
            <BookCardMetaItem label="Read:" icon="fa-calendar-check">
              {read}
            </BookCardMetaItem>
            <BookCardMetaItem label="Pages:" icon="fa-file">
              {pages}
            </BookCardMetaItem>
            {/* TODO: Find way to see if book has notes to render label. */}
            {<MDXRenderer>{body}</MDXRenderer> && (
              <span>
                <i className="fas fa-sticky-note pr-2" />
                <span className="font-display text-xs">Notes Available</span>
              </span>
            )}
            {categories && (
              <BookCardMetaItem icon="fa-tags">
                {categories.map((item, i) => (
                  <React.Fragment key={categoryKeys[i]}>
                    {' '}
                    {i !== 0 && ','}{' '}
                    <Link
                      to={`/book-category/${slugify(item.toLowerCase())}/`}
                      className="text-gray-600 underline"
                    >
                      {item}
                    </Link>
                  </React.Fragment>
                ))}
              </BookCardMetaItem>
            )}
          </BookCardMetaContainer>
          <section className="mb-4 md:mb-0">
            <ExpandCollapse previewHeight="175px">
              <div className="font-body text-base">
                <MDXRenderer>{body}</MDXRenderer>
              </div>
            </ExpandCollapse>
          </section>
        </div>
      </BookCardContainer>
    );
  },
);

export default BookCard;
