/* eslint-disable no-fallthrough */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { useId } from 'react-id-generator';
import ReactTooltip from 'react-tooltip';
import { MetaShadow } from '../shadow/shadow';

export function BookCardContainer({ children, isMobile }) {
  return (
    <div
      className="flex md:flex-row flex-col mb-10 border-b last:border-b-0 rounded md:rounded-none shadow-md md:shadow-none p-2 md:p-0"
      css={css`
        background: ${isMobile ? 'var(--bgRaised)' : ''};
        border-color: var(--border);
      `}
    >
      {children}
    </div>
  );
}

function BookMeta({ label, children, iconClassName, metaColorPick }) {
  return (
    <span className="font-mono text-gray-400 text-sm mr-4">
      <b style={{ color: metaColorPick && metaColorPick.foreground }}>
        <i className={`fa ${iconClassName} pr-2`} />
        {label}:
      </b>{' '}
      {children}
    </span>
  );
}

export function BookRatingMeta({ color, children, label, secondary, suffix, last, contents }) {
  return (
    <span
      className={`font-mono text-white font-bold text-sm md:text-1xl ${last ? '' : 'mr-2'} ${
        contents ? '' : 'md:contents'
      }`}
    >
      <span className="font-mono" style={{ color: color.lightVibrant }}>
        {label} {secondary && <span className="hidden md:inline-block">{secondary}:</span>}{' '}
      </span>
      <span>
        <span className="text-xl">{children}</span>{' '}
        {suffix && <span className="-ml-1">{`${suffix} `}</span>}
      </span>
    </span>
  );
}

export function BookCardRating({ rating }) {
  return (
    <span>
      <b>Rating:</b>
      <span className="text-2xl">{rating}</span>
      /10 <br />
    </span>
  );
}

export function BookCardReadingEase({ readability, isMobile }) {
  const readingEaseKeys = useId(4);

  return (
    <span
      css={css`
        i {
          display: inline-block;
          margin-right: 0.2rem;
        }
      `}
    >
      <b>{isMobile ? 'Readability:' : 'Reading Ease:'}</b>{' '}
      {Array.from({ length: 4 }).map((_, i) => (
        <i
          key={readingEaseKeys[i]}
          css={css`
            font-size: 0.5rem;
          `}
          className={`${readability > i ? 'fa' : 'far'} fa-circle`}
        />
      ))}
    </span>
  );
}

export function BookCardReadTime({ readTime, isMobile }) {
  return (
    <span>
      <b>{isMobile ? 'Read Time:' : 'Reading Time:'}</b> {readTime}{' '}
      <span className="hidden md:inline-block">hours</span> <span className="md:hidden">hrs</span>
    </span>
  );
}

export function BookCardTitle({ title, slug }) {
  return (
    <Link
      to={slug}
      className="bg-gray-200 md:hover:underline md:hover:text-primary-500 md:hover:font-bold"
    >
      <h2 className="mt-0 mb-1 font-bold text-2xl">
        {title.length < 100 ? title : title.split(':')[0]}
      </h2>
    </Link>
  );
}

export function BookCardAuthor({ bookAuthor }) {
  return <p className="mt-0 font-semibold font-sans text-gray-600">{bookAuthor}</p>;
}

export function BookCover({ slug, fixed, height, width }) {
  const style = {
    height,
    width,
  };
  return (
    <div className="w-2/5 md:w-full md:mr-4 mr-3">
      {' '}
      <Link to={slug}>
        {fixed && (
          <GatsbyImage
            image={fixed}
            className="rounded w-40 h-64"
            style={style}
            alt="Book Author image"
          />
        )}
      </Link>
    </div>
  );
}

export function BookCardMetaContainer({ children, isMobile }) {
  return (
    <div
      className="font-mono overflow-x-auto flex flex-no-wrap md:block whitespace-no-wrap md:whitespace-normal"
      css={css`
        color: var(--textLabel);
        .fa {
          display: ${isMobile ? 'inline' : 'inline-block'};
        }
        span {
          font-size: 0.8rem;
          display: ${isMobile ? 'flex' : 'inline-block'};
          flex-wrap: nowrap;
          flex-direction: row;
          margin: 0.25rem 1rem 0.25rem 0;
        }
      `}
    >
      <MetaShadow />
      {children}
    </div>
  );
}

export function BookCardMetaItem({ children, label, icon }) {
  return (
    <span>
      <b>
        <i className={`text-gray-700 fa fa ${icon} pr-2`} />
        {label && label}
      </b>{' '}
      {children}
    </span>
  );
}

export function BookSubHeader({ bookCount }) {
  return (
    <p
      className="font-display text-center border py-2 px-4 rounded"
      css={css`
        color: var(--textNormal);
        border-color: var(--border);
        background-color: var(--bgBadge);
        span {
          display: inline-block;
          margin: 0.25rem 1rem 0.25rem 0;
        }
      `}
    >
      A growing list of books I've read over the years. Check back periodically for updates.
      Currently posted: <b>{bookCount}</b> books.
    </p>
  );
}

export function BookSubHeaderQuote() {
  return (
    <blockquote className="text-base font-display">
      Say hello{' '}
      <a
        className="hover:text-blue-500"
        target="_blank"
        rel="noopener noreferrer"
        href="http://www.twitter.com/juvoni"
        css={css`
          color: var(--textNormal);
        `}
      >
        {' '}
        @juvoni <i className="fab fa-twitter" />
      </a>{' '}
      and send me your book recommendations. Bonus points for older books and non-bestsellers.
    </blockquote>
  );
}

export function BookMedium({ readOn }) {
  const readOnKeys = useId(readOn.length);
  return (
    <React.Fragment>
      {readOn.map((medium, i) => {
        let dataTip;
        let dataFor;

        // eslint-disable-next-line default-case
        switch (medium) {
          case 'print':
            dataTip = 'Print';
            dataFor = 'book-print';
            break;
          case 'audible':
            dataTip = 'Audible';
            dataFor = 'book-audible';
            break;
          case 'kindle':
            dataTip = 'Kindle';
            dataFor = 'book-kindle';
            break;
        }

        const iconClasses = classNames({
          'fa-book': medium === 'print',
          'fa-headphones-alt': medium === 'audible',
          'fa-tablet-alt': medium === 'kindle',
        });
        return (
          <span className="mr-2" key={readOnKeys[i]}>
            <i
              className={`fas ${iconClasses} fa-sm`}
              data-tip={dataTip}
              data-for={dataFor}
              data-effect="solid"
            />
            <ReactTooltip id={dataFor} />
          </span>
        );
      })}
    </React.Fragment>
  );
}

export default BookMeta;
