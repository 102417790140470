/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

export default function header({ children, heroBg }) {
  const image = getImage(heroBg);

  const imgStack = ['linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.7))', image];

  return (
    <BgImage
      css={css`
        &::after,
        &::before {
          background-position: 50% 10%;
          background-size: cover;
        }
      `}
      image={imgStack}
    >
      <div>
        <div className="container py-12  md:pb-24 md:pt-24 ">
          <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">{children}</h1>
        </div>
      </div>
    </BgImage>
  );
}
