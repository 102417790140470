/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import tw from 'twin.macro';

const bodyCss = css`
  display: inline;
`;

const buttonCss = css`
  background: var(--bgExpander);
  color: var(--textNormal);
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-tap-highlight-color: transparent;

  ${tw`font-sans text-sm underline`};

  &:focus {
    outline: 0;
  }

  &:hover {
    ${tw`text-blue-500`};
  }

  &::before {
    background: var(--expanderGradient);
    content: '';
    position: absolute;
    top: 0;
    left: -75px;
    width: 75px;
    height: 100%;
  }
`;

const contentCss = css`
  overflow: hidden;
  position: relative;
`;

const expandedButtonCss = css`
  padding-left: 5px;
  position: relative;
  right: auto;
  bottom: auto;

  &::before {
    content: none;
  }
`;

function Expander({ children, previewHeight }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div css={contentCss} style={!expanded && previewHeight ? { maxHeight: previewHeight } : {}}>
      <div css={bodyCss}>{children}</div>

      <button
        aria-expanded={expanded}
        aria-label="Collapse"
        css={[buttonCss, expanded && expandedButtonCss]}
        onClick={() => {
          setExpanded(!expanded);
        }}
        type="button"
      >
        {expanded ? 'Collapse' : '... Expand'}
      </button>
    </div>
  );
}

export default Expander;
