/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import orderby from 'lodash.orderby';
import React from 'react';
import { useId } from 'react-id-generator';
import SVG from 'react-inlinesvg';
import LazyLoad from 'react-lazyload';
import slugify from 'slugify';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config';
import { CategoryIconMapper } from '../../utils/book-utils';
import { FullShadow } from '../ui/shadow/shadow';

const config = resolveConfig(tailwindConfig);
const {
  theme: {
    colors: { primary },
  },
} = config;

function CategoryCard({ category, count }) {
  const iconDimensions = {
    height: '50px',
    width: '50px',
  };
  const normalizedCategory = slugify(category.toLowerCase());

  return (
    <Link
      to={`/book-category/${normalizedCategory}/`}
      className="w-48 h-32 rounded-lg shadow hover:shadow-md mr-4 flex flex-col whitespace-no-wrap items-center"
      css={css`
        background-color: var(--bgRaised);
        transition: color 0.2s ease-out, background 0.2s ease-out;
        flex: 0 0 12rem;
      `}
    >
      <>
        <SVG
          cacheRequests
          description={category}
          preProcessor={(code) => code.replace(/fill=".*?"/g, `fill="${primary[600]}"`)}
          src={CategoryIconMapper(normalizedCategory)}
          title={category}
          style={iconDimensions}
          className="mt-5 mb-2"
        />
      </>
      <div className="flex flex-col font-display items-center justify-end">
        <span
          className="font-semibold text-sm"
          css={css`
            color: var(--textCategory);
          `}
        >
          {category}
        </span>
        <span
          className="text-xs"
          css={css`
            color: var(--textLabel);
          `}
        >
          {count} books
        </span>
      </div>
    </Link>
  );
}

export default function BookCategoryList() {
  const {
    bookCategories: { group },
  } = useStaticQuery(graphql`
    {
      bookCategories: allBook {
        group(field: frontmatter___categories) {
          fieldValue
          totalCount
        }
      }
    }
  `);

  const items = [
    'Business',
    'Psychology',
    'Personal Development',
    'Science',
    'Philosophy',
    'Economics',
    'History',
    'Communication',
    'Sociology',
    'Biographies & Memoirs',
    'Health',
    'Self Help',
    'Technology',
    'Politics',
    'Fiction',
    'Management & Leadership',
    'Education',
    'Entrepreneurship',
    'Productivity',
    'Fitness',
  ];

  const categories = orderby(group, ['totalCount'], ['desc']);
  const categoryKeys = useId(categories.length);
  const scrollBtn = React.createRef();
  const categoryWindow = React.createRef();

  function handleScroll() {
    categoryWindow.current.scrollLeft += 500;
  }

  return (
    <>
      <h3 className="uppercase text-lg text-gray-600 tracking-wide text-center">Categories</h3>
      <FullShadow height="170px" />
      <div
        className="flex flex-row flex-no-wrap overflow-x-auto mb-4 py-4 px-1"
        ref={categoryWindow}
        css={css`
          -webkit-overflow-scrolling: auto;
          &:hover {
            ::-webkit-scrollbar {
              -webkit-appearance: none;
              height: 0.3rem;
            }
            ::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
          }
        `}
      >
        {categories.slice(0, 20).map((category, i) => (
          <LazyLoad height={250} offset={-50} key={categoryKeys[i]} once>
            <CategoryCard category={category.fieldValue} count={category.totalCount} />
          </LazyLoad>
        ))}
      </div>
      <LazyLoad height={250} offset={-50} once>
        <div
          className="block"
          css={css`
            position: absolute;
            @media (min-width: 868px) {
              position: relative;
            }

            z-index: 800;
          `}
        >
          <span
            className="hidden md:absolute md:block"
            css={css`
              position: absolute;
              @media (min-width: 868px) {
                right: -50px;
                bottom: 80px;
              }
              @media (max-width: 868px) {
                left: 310px;
              }

              bottom: 80px;
              opacity: 0.9;
            `}
          >
            <button
              type="button"
              ref={scrollBtn}
              onClick={handleScroll}
              className="flex flex-col items-center justify-center shadow hover:shadow-md p-4 h-8 w-8 bg-gray-200 rounded-full"
              css={css`
                color: var(--textNormal);
                background: var(--bgRaised);
              `}
            >
              <i className="text-gray-500 fa fa-xs fa-arrow-right" />
            </button>
          </span>
        </div>
      </LazyLoad>
    </>
  );
}
