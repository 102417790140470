/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { memo } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default memo(({ setBooks }) => {
  const filterSelection = [
    ['Date Posted', 'date', 'Order books by date posted (Desc)'],
    ['Rating', 'rating', 'Order books by rating (Desc)'],
    ['Reading Ease', 'readEase', 'Order by reading difficulty (Desc)'],
    ['Date Read', 'read', 'Order by date in which I read the book (Desc)'],
    ['Title', 'title', 'Order by book title (Desc)'],
  ];

  const filterSelectionMap = [
    { description: 'Order books by date posted (Desc)', label: 'Date Posted', value: 'date' },
    { description: 'Order books by rating (Desc)', label: 'Rating', value: 'rating' },
    {
      description: 'Order by reading difficulty (Desc)',
      label: 'Reading Ease',
      value: 'readEase',
    },
    {
      description: 'Order by date in which I read the book (Desc)',
      label: 'Date Read',
      value: 'read',
    },
    { description: 'Order by book title (Desc)', label: 'Title', value: 'title' },
  ];

  const defaultOption = filterSelection[0][0];

  return (
    <div className="w-3/4 w-5/6 p-2 flex md:justify-end">
      <div className="relative font-display">
        <span
          className="text-sm font-semibold text-gray-600"
          css={css`
            vertical-align: -webkit-baseline-middle;
          `}
        >
          Sort By
        </span>
        {/* TODO: Make book filter accessible. */}
        <Dropdown
          className="z-50 absolute inline-flex flex-col w-40 items-center text-sm ml-2 border py-2 px-4 rounded hover:shadow"
          css={css`
            background-color: var(--bgRaised);
            color: var(--textLabel);
            border-color: var(--bgInput);
            cursor: pointer;
            .Dropdown-option {
              padding: 0.1rem;
              margin: 0.25rem 0;
              color: inherit;
              &:hover {
                font-weight: bold;
                background-color: transparent;
              }
            }
          `}
          arrowClosed={<i className="fas fa-sort-down fa-lg flex items-end text-gray-600" />}
          arrowOpen={
            <i
              className="fas fa-sort-up fa-lg flex items-end text-gray-600"
              css={css`
                margin-top: 6px;
              `}
            />
          }
          controlClassName="font-semibold flex"
          arrowClassName="text-center"
          arrowWrapperClassName="text-center"
          placeholderClassName="mr-2"
          options={filterSelectionMap}
          // eslint-disable-next-line no-underscore-dangle
          onChange={({ value }) => {
            setBooks(value);
          }}
          value={defaultOption}
          placeholder="Select an option"
        />
      </div>
    </div>
  );
});
