/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import SiteConfig from '../data/SiteConfig';

export default function AmazonBar({ link }) {
  return (
    <div className="border border-yellow-500 bg-yellow-200 px-3 py-2 my-6 flex flex-wrap items-center md:justify-between justify-center">
      <h3 className="my-1 text-orange-800 font-semibold text-base ">Support the Author(s)</h3>
      <AmazonLink link={link} />
    </div>
  );
}

export const AmazonLink = ({ link }) => (
  <a
    href={`${link}?tag=${SiteConfig.booksAffiliateLink}`}
    target="_blank"
    rel="noopener noreferrer"
    css={css`
      background: #ff9900;
      border: 1px solid #ff9900;
      overflow: hidden;
      .icon {
        margin: 0 0.75rem 0 -1rem !important;
      }
    `}
    className="text-white  px-4 rounded flex items-center justify-between"
  >
    <span className="icon bg-white text-center inline-block mr-3 px-4 py-1 -ml-4">
      <i className="fab fa-amazon" />
    </span>{' '}
    <span
      className="font-semibold text-sm"
      css={css`
        text-shadow: 1px 1px 1px #ad4101;
        color: var(--textLabel);
      `}
    >
      Get on Amazon
    </span>
  </a>
);

export const AmazonLinkGray = ({ link }) => (
  <a
    href={`${link}?tag=${SiteConfig.booksAffiliateLink}`}
    target="_blank"
    rel="sponsored noopener noreferrer"
    css={css`
      overflow: hidden;
      width: 165px;
      .icon {
        margin: 0 0.75rem 0 -1rem !important;
      }
    `}
    className="text-white px-4 rounded flex border border-gray-700 items-center hover:shadow-lg"
  >
    <span className="icon bg-gray-700 text-white text-center self-stretch flex items-center justify-center  inline-block px-2 py-2 leading-none ">
      <i className="fab fa-amazon" />
    </span>{' '}
    <span
      className="font-semibold text-xs"
      css={css`
        color: var(--textLabel);
      `}
    >
      Get on Amazon
    </span>
  </a>
);

export const AmazonButton = ({ link }) => (
  <a
    href={`${link}?tag=${SiteConfig.booksAffiliateLink}`}
    target="_blank"
    rel="sponsored noopener noreferrer"
    css={css`
      overflow: hidden;
      width: 165px;
      .icon {
        margin: 0 0.75rem 0 -1rem !important;
      }
    `}
    className="px-6 py-2 rounded flex border border-gray-400 items-center justify-center shadow-xs hover:shadow-md"
  >
    <span className="icon text-gray-400">
      <i className="fab fa-amazon" />
    </span>{' '}
    <span
      className="font-semibold text-xs"
      css={css`
        color: var(--textCaption);
      `}
    >
      Get on Amazon
    </span>
  </a>
);
