/* eslint-disable react/jsx-no-bind */
import 'animate.css/animate.min.css';
import { graphql } from 'gatsby';
import orderby from 'lodash.orderby';
import React, { useReducer } from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { isMobile } from 'react-device-detect';
import 'react-dropdown/style.css';
import { useId } from 'react-id-generator';
import LazyLoad from 'react-lazyload';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';
import BookCard from '../components/template/BookCard';
import BookCategoryList from '../components/template/BookCategoryList';
import Banner from '../components/ui/banner/banner';
import BookFilter from '../components/ui/book/BookFilter';
import { BookSubHeader, BookSubHeaderQuote } from '../components/ui/book/bookUi';
import CurrentlyReading from '../components/ui/book/CurrentlyReading';

export default function ReadingList({ data }) {
  const bookHeight = isMobile ? 500 : 400;

  const initialState = { books: data.allBook.nodes, filter: 'date', order: 'desc' };

  function sortedTableReducer(oldBookView, newBookView) {
    const { books, filter, order } = { ...oldBookView, ...newBookView };
    return { books: orderby(books, [`frontmatter.${filter}`], [order]), filter, order };
  }
  const [sortedBooks, sortedDispatch] = useReducer(sortedTableReducer, initialState);

  const { books } = sortedBooks;

  function sortBookDispatch(filter) {
    let order = 'asc';

    switch (filter) {
      case 'date':
        order = 'desc';
        break;
      case 'title':
        order = 'asc';
        break;
      case 'rating':
        order = 'desc';
        break;
      case 'readEase':
        order = 'desc';
        break;
      case 'read':
        order = 'desc';
        break;
      default:
        break;
    }
    sortedDispatch({ filter, order });
  }

  const bookIds = useId(books.length);
  return (
    <Layout>
      <SEO />
      <Banner heroBg={data.heroBg}>Books</Banner>
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div className="w-full md:pr-4">
            <BookSubHeader bookCount={books.length} />
            <BookSubHeaderQuote />
            <CurrentlyReading />
            <AnimationOnScroll
              animateIn="animate__fadeIn"
              animateOnce
              initiallyVisible
              offset={300}
            >
              <BookCategoryList />
            </AnimationOnScroll>
            <BookFilter setBooks={sortBookDispatch} />
            <div className="mt-8">
              {books &&
                books.map((book, i) => {
                  const { frontmatter, excerpt } = book;

                  return (
                    <LazyLoad height={bookHeight} offset={50} key={bookIds[i]} once>
                      <BookCard
                        slug={frontmatter.slug}
                        fixed={frontmatter.image.childImageSharp.gatsbyImageData}
                        rating={frontmatter.rating}
                        readEase={frontmatter.readEase}
                        readTime={frontmatter.readTime}
                        bookLink={frontmatter.bookLink}
                        title={frontmatter.title}
                        bookAuthor={frontmatter.bookAuthor}
                        publicationYear={frontmatter.publicationYear}
                        read={frontmatter.read}
                        pages={frontmatter.pages}
                        categories={frontmatter.categories}
                        body={excerpt.body}
                        isMobile={isMobile}
                      />
                    </LazyLoad>
                  );
                })}
            </div>
            <p>
              <small>
                Disclaimer: I am a participant in the Amazon Services LLC Associates Program and
                earn a small commision on books purchased through my site. Your support enables me
                to post new books more consistently.
              </small>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    heroBg: file(relativePath: { eq: "books-header-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
      }
    }
    allBook(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        frontmatter {
          date
          read(formatString: "LL")
          readOrder: read
          title
          author
          slug
          page
          publicationYear
          image {
            childImageSharp {
              gatsbyImageData(width: 160, height: 240, layout: FIXED, formats: [AUTO, WEBP])
            }
          }
          bookAuthor
          bookLink
          isbn
          pages
          rating
          readEase
          readTime
          categories
        }
        excerpt {
          body
        }
      }
    }
  }
`;
