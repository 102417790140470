import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { FullShadow } from '../shadow/shadow';

export default function CurrentlyReadingWidget() {
  const data = useStaticQuery(graphql`
    {
      recentBooks: allReading(limit: 5, sort: { fields: frontmatter___date, order: DESC }) {
        nodes {
          id
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="mb-4">
      {' '}
      <hr className="block md:hidden" />
      <h3 className="uppercase text-lg text-gray-600 tracking-wide text-center">
        Currently Reading
      </h3>
      <FullShadow />
      <div className="flex flex-row overflow-x-auto">
        <>
          {' '}
          {data.recentBooks.nodes.map((item) => {
            const { frontmatter: book } = item;

            return (
              <div key={item.id} className="w-1/2 md:w-full px-2">
                <GatsbyImage
                  image={book.image.childImageSharp.gatsbyImageData}
                  className="rounded"
                  alt={book.title}
                  style={{
                    height: '187px',
                    margin: '0 auto',
                    width: '125px',
                  }}
                />
                <h4 className="text-center text-sm mt-2">{book.title}</h4>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
}
