/* eslint-disable import/prefer-default-export */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

export const FullShadow = ({ height }) => (
  <div
    className="block md:hidden"
    css={css`
      position: absolute;
      width: 20px;
      height: ${height || '300px'};
      z-index: 800;
      right: 15px;
      background: var(--expanderGradientDuo);
    `}
  />
);

export const MetaShadow = () => (
  <div
    className="block md:hidden"
    css={css`
      position: absolute;
      width: 50px;
      height: 30px;
      z-index: 800;
      right: 25px;
      background: var(--expanderGradient);
    `}
  />
);
